<template>
  <div class="home">
    <div class="home-top-section">
      <HomeBanners />
      <BuyCrypto />
      <HomeNavigation />
    </div>
    <CategoryGamesSlider
      v-if="mainCasinoCategory"
      :category="mainCasinoCategory.code"
      size="m"
      class="main-games"
    />
    <div v-else-if="isLoading" class="main-games" />
    <TournamentsSlider v-if="isEnabledActiveTournaments" />
    <LastBets :tabs="tabs" header-size="2xl" />
    <About />
  </div>
</template>

<script setup lang="ts">
import { useCasinoStore } from '@st/casino/stores/useCasinoStore'
import type { Tab } from '@st/ui/components/StTabs/types'
import { useTournamentsStore } from '@st/tournaments/stores/useTournamentsStore'

const { t } = useI18n()

const { mainPageCategories, isLoading } = storeToRefs(useCasinoStore())
const mainCasinoCategory = computed(() => mainPageCategories.value?.[0])

/**
 * @see useGameRecursiveMessage.ts
 */
onMounted(() => {
  window?.parent?.postMessage({ type: 'openedMainPage' }, '*')
})

useHead({
  title: t('pages.main'),
})

const tabs: Tab[] = [
  {
    id: 'casino',
    label: t('tapBar.casino'),
    icon: 'cherry-solid',
  },
  {
    id: 'sport',
    label: t('tapBar.sport'),
    icon: 'all-sports',
  },
  {
    id: 'big-bets-casino',
    label: t('lastBets.bigBets'),
    icon: 'coin-stack-1-solid',
  },
]

const { isEnabledActiveTournaments } = storeToRefs(useTournamentsStore())
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-600);
  padding-bottom: var(--spacing-600);
}

.home-top-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-350);
}

.main-games {
  min-height: 256px;
}
</style>
