<template>
  <section class="buy-crypto" data-t="buy-crypto">
    <div class="crypto-badges">
      <div
        v-for="currency in currencies"
        :key="currency.id"
        class="currency-item"
        data-t="currency-item"
      >
        <img :src="currency.icon" :alt="currency.id" width="28" height="28" />
        <span class="code">{{ currency.id }}</span>
      </div>
    </div>
    <div class="crypto-actions" data-t="crypto-actions">
      <div class="crypto-text">
        <p>{{ t('buyCrypto.noCrypto') }}</p>
        <span>{{ t('buyCrypto.card') }}</span>
      </div>
      <div class="buy-wrapper">
        <ClientOnly>
          <StButton
            v-if="isAuthenticated"
            :label="t('buyCrypto.button')"
            type="gray"
            size="l"
            replace
            :disabled="!isEnabledBuyCrypto"
            :to="{ query: { modal: 'payments', tab: 'buy' } }"
          />
          <StButton
            v-else
            :label="t('buyCrypto.button')"
            type="gray"
            size="l"
            replace
            :disabled="!isEnabledBuyCrypto"
            :to="{ query: { modal: 'login' } }"
          />
        </ClientOnly>
        <div v-if="!isEnabledBuyCrypto" class="soon">
          {{ t('buyCrypto.soon') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUserStore } from '@st/user/stores/useUserStore'
import { useCurrenciesBadges } from './useCurrenciesBadges'

const { currencies } = useCurrenciesBadges()
const { t } = useI18n()
const { isEnabledBuyCrypto } = useBuyCryptoEnabled()
const { isAuthenticated } = storeToRefs(useUserStore())
</script>

<style scoped>
.buy-crypto {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-150) var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.crypto-badges {
  scrollbar-width: none;

  overflow: scroll;
  display: flex;
  align-items: center;

  max-width: 820px;

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.crypto-badges::-webkit-scrollbar {
  display: none;
}

.currency-item {
  position: relative;

  display: flex;
  gap: var(--spacing-125);
  align-items: center;

  padding-block: var(--spacing-050);

  border-radius: var(--border-radius-full);

  span {
    font: var(--desktop-text-md-medium);
    color: var(--text-tertiary);
  }

  &:not(:first-child) {
    margin-left: var(--spacing-400);

    &::before {
      content: '';

      position: absolute;
      left: -16px;

      width: 1px;
      height: 13px;

      background-color: var(--border-primary);
    }
  }

  @media screen and (width >= 1440px) and (width <= 1536px) {
    &:last-child {
      display: none;
    }
  }
}

.crypto-actions {
  display: flex;
  gap: var(--spacing-350);
}

.crypto-text {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font: var(--desktop-text-md-semibold);
    color: var(--text-primary);
  }

  span {
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }
}

.buy-wrapper {
  position: relative;
}

.soon {
  user-select: none;

  position: absolute;
  top: -8px;
  right: -10px;

  padding: var(--spacing-025) var(--spacing-050);

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);

  background: var(--background-ghost-tertiary);
  border-radius: var(--border-radius-full, 999px);
}
</style>
