<template>
  <section>
    <header class="header">
      <div class="title">
        <StIcon name="cup-solid" :size="32" />
        <h2>
          {{ t('tournament.title') }}
        </h2>
      </div>
      <StTabs
        v-if="!isDefaultContainer"
        v-model="selectedTab"
        :tabs="tabs"
        type="separate"
      />
    </header>
    <div class="tournaments" :class="{ default: isDefaultContainer }">
      <div v-if="tournaments.length <= 3" class="default-container">
        <TournamentCard
          v-for="item in tournaments"
          :key="item.id"
          :card="item"
        />
      </div>
      <StCarousel
        v-if="tournaments.length > 3"
        :items-to-show="3.5"
        :items-to-scroll="1"
      >
        <StCarouselItem v-for="item in tournaments" :key="item.id">
          <TournamentCard :card="item" class="slider-tournament" />
        </StCarouselItem>
      </StCarousel>
    </div>
  </section>
</template>

<script setup lang="ts">
import TournamentCard from '../TournamentCard/TournamentCard.vue'
import { useTournamentsSlider } from './useTournamentsSlider'

const { t } = useI18n()

const { selectedTab, tabs, tournaments, isDefaultContainer } =
  useTournamentsSlider()
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  color: var(--text-tertiary);

  h2 {
    margin: 0;
    font: var(--desktop-text-2xl-semibold);
    color: var(--text-primary);
  }
}

.default-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-200);
  padding-bottom: var(--spacing-300);
}

.slider-tournament {
  flex-grow: 1;
}

.tournaments {
  padding-top: var(--spacing-200);

  &.default {
    .default-container {
      padding-bottom: 0;
    }
  }
}
</style>
