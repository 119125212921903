<template>
  <section class="home-navigation" data-t="home-navigation">
    <CardHomeNavigation
      v-for="item in cardsConfig"
      :key="item.title"
      class="card"
      v-bind="item"
    />
  </section>
</template>

<script setup lang="ts">
import CardHomeNavigation from './CardHomeNavigation.vue'
import { useHomeNavigation } from './useHomeNavigation'

const { cardsConfig } = useHomeNavigation()
</script>

<style scoped>
.home-navigation {
  display: flex;
  gap: var(--spacing-200);
}

.card {
  flex: 1 1 0;
  min-width: 0;
}
</style>
