<template>
  <article class="home-navigation-card" data-t="home-navigation-card">
    <NuxtI18nLink class="link" :to="props.link">
      <header :class="headerClasses">
        <div class="header-title">
          <div class="title-wrapper">
            <h3 class="title">{{ title }}</h3>
            <StIcon class="title-chevron" name="chevron-right" />
          </div>
          <div :class="tagClasses">
            <img
              v-if="tagIcon"
              :src="tagIcon"
              alt="tag-icon"
              data-t="home-navigation-tag"
            />
            {{ tagName }}
          </div>
        </div>
        <div class="type-image-wrapper">
          <img class="type-image" :src="img" alt="type" />
        </div>
      </header>
    </NuxtI18nLink>
    <div class="tag-content">
      <span class="tag-title">{{ topTitle }}</span>
      <template v-if="topList.length">
        <NuxtI18nLink
          v-for="row in topList"
          :key="row.title"
          class="top-list"
          :to="row.link"
        >
          <div class="top-list-icon">
            <StCounter
              v-if="row.count"
              bg-color="orange"
              size="xxs"
              :label="row.count"
              class="top-list-badge"
            />
            <StIcon
              v-if="row.icon"
              :name="row.icon"
              size="40"
              style="background-color: var(--text-secondary)"
            />
            <StIcon
              v-if="row.iconRaw"
              :svg="row.iconRaw"
              size="40"
              style="background-color: var(--text-secondary)"
            />
          </div>
          <div class="top-list-item">
            <span class="top-list-title">{{ row.title }}</span>
            <span class="top-list-subtitle">{{ row.subtitle }}</span>
          </div>
          <StButton
            class="top-list-button"
            size="m"
            type="gray"
            icon="chevron-right"
          />
        </NuxtI18nLink>
      </template>
      <div v-else>
        <div v-for="i in 3" :key="i" class="top-list">
          <StSkeletonLoader
            type="circle"
            width="40px"
            height="40px"
            bg-color="var(--background-secondary)"
            :shimmer-opacity="0.28"
          />
          <div class="top-list-item-skeletons">
            <StSkeletonLoader
              class="top-list-title"
              height="14px"
              width="140px"
              bg-color="var(--background-secondary)"
              :shimmer-opacity="0.28"
            />
            <StSkeletonLoader
              class="top-list-subtitle"
              height="10px"
              width="240px"
              bg-color="var(--background-secondary)"
              :shimmer-opacity="0.28"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="home-navigation-footer">
      <img
        v-for="(logo, index) in logos"
        :key="index"
        :src="logo"
        alt="logo"
        class="logo"
      />
    </div>
  </article>
</template>

<script setup lang="ts">
import type { CardsConfig } from './types'

const props = defineProps<CardsConfig>()
const headerClasses = computed(() => ['header', `type-${props.type}`])
const tagClasses = computed(() => ['tag-wrapper', `type-${props.type}`])
</script>

<style scoped>
@property --alpha {
  inherits: false;
  initial-value: 85%;
  syntax: '<percentage>';
}

.home-navigation-card {
  display: flex;
  flex-direction: column;
}

.link {
  color: inherit;
  text-decoration: none;
}

.title {
  margin: 0;
  font: var(--desktop-text-2xl-semibold);
}

.header {
  cursor: pointer;

  position: relative;

  display: flex;
  grid-template-columns: 1fr min-content;
  justify-content: space-between;

  background: var(--background-primary);
  border-top-left-radius: var(--border-radius-150);
  border-top-right-radius: var(--border-radius-150);

  transition: --alpha 0.5s; /* stylelint-disable-line */

  /* stylelint-disable */

  &.type-casino {
    background: var(--background-primary)
      radial-gradient(
        var(--alpha) 154.68% at 8.11% 0%,
        rgb(184 38 54 / 100%) 5%,
        rgb(184 38 54 / 68%) 20%,
        rgb(38 38 49 / 0%) 65%
      );
  }

  &.type-sport {
    background: var(--background-primary)
      radial-gradient(
        var(--alpha) 138.72% at 8.11% 0%,
        rgb(0 183 96 / 75%) 5%,
        rgb(0 183 96 / 38%) 20%,
        rgb(38 38 49 / 0%) 65%
      );
  }

  &.type-cyber {
    background: var(--background-primary)
      radial-gradient(
        var(--alpha) 129.05% at 8.11% 0%,
        rgb(209 112 255 / 75%) 0%,
        rgb(209 112 255 / 38%) 20%,
        rgb(38 38 49 / 0%) 72.19%
      );
  }

  /* stylelint-enable */
}

@-moz-document url-prefix() {
  .header {
    &.type-casino {
      background: var(--background-primary)
        radial-gradient(
          var(--alpha, 85%) 154.68% at 8.11% 0%,
          rgb(184 38 54 / 100%) 5%,
          rgb(184 38 54 / 68%) 20%,
          rgb(38 38 49 / 0%) 65%
        );
    }

    &.type-sport {
      background: var(--background-primary)
        radial-gradient(
          var(--alpha, 85%) 138.72% at 8.11% 0%,
          rgb(0 183 96 / 100%) 5%,
          rgb(0 183 96 / 68%) 20%,
          rgb(38 38 49 / 0%) 65%
        );
    }

    &.type-cyber {
      background: var(--background-primary)
        radial-gradient(
          var(--alpha, 85%) 129.05% at 8.11% 0%,
          rgb(209 112 255 / 60%) 0%,
          rgb(209 112 255 / 35%) 20%,
          rgb(38 38 49 / 0%) 72.19%
        );
    }
  }
}

.title-chevron {
  transform: translateX(-10px);

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-050);

  opacity: 0;

  transition:
    opacity 0.25s ease-in-out,
    transform 0.25s ease-in-out;
}

.type-image {
  transform: scale(1);
  max-width: 100%;
  height: auto;
  transition: transform 0.2s;
}

.header:hover {
  --alpha: 95%;

  .type-image {
    transform: scale(1.1) rotate(-4deg);
  }

  .title-chevron {
    transform: translateX(0);
    opacity: 1;
  }
}

.title-wrapper {
  display: flex;
  gap: var(--spacing-100);
  margin-bottom: var(--spacing-075);
}

.header-title {
  padding: var(--spacing-200);
}

.type-image-wrapper {
  position: absolute;
  right: 0;
  width: 170px;
  height: 100px;
}

.tag-wrapper {
  display: inline-flex;
  gap: var(--spacing-050);

  width: max-content;
  height: max-content;
  padding: var(--spacing-050) var(--spacing-125);

  font: var(--desktop-text-xs-semibold);
  white-space: nowrap;

  border-radius: var(--border-radius-full);

  &.type-casino {
    color: #ff395d; /* stylelint-disable-line */
    background-color: rgb(255 57 93 / 20%);
  }

  &.type-sport {
    color: var(--accent-sport-primary);
    background-color: rgb(2 209 110 / 20%);
  }

  &.type-cyber {
    color: #d170ff; /* stylelint-disable-line */
    background-color: rgb(209 112 255 / 20%);
  }
}

.tag-title {
  padding: 0 var(--spacing-100);
  font: var(--desktop-text-xs-semibold);
  color: var(--text-tertiary);
}

.tag-content {
  min-height: 224px;
  padding: var(--spacing-075) var(--spacing-100) var(--spacing-100)
    var(--spacing-100);

  background: var(--background-primary);
  border-bottom-right-radius: var(--border-radius-150);
  border-bottom-left-radius: var(--border-radius-150);
}

.top-list-badge {
  position: absolute;
  z-index: 1;
  top: -2px;
  left: -8px;

  box-shadow: 0 0 0 2px var(--background-primary);
}

.top-list-button {
  width: var(--spacing-300);
  height: var(--spacing-300);
  padding: 0 !important;
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.top-list {
  cursor: pointer;

  display: grid;
  grid-template-columns: 40px 1fr 20px;
  gap: var(--spacing-150);
  align-items: center;

  min-height: 56px;
  margin-top: var(--spacing-050);
  padding: var(--spacing-075) var(--spacing-100) var(--spacing-075)
    var(--spacing-200);

  color: var(--text-primary);
  text-decoration: none;

  border-radius: var(--border-radius-150);

  &:not(:last-child) {
    margin-bottom: var(--spacing-125);
  }

  & a {
    justify-self: end;
    width: min-content;
    height: min-content;
  }

  &:hover {
    background-color: var(--background-secondary);

    .top-list-badge {
      box-shadow: 0 0 0 2px var(--background-secondary);
    }

    .top-list-button {
      transform: translateX(var(--spacing-100));
      box-shadow: var(--shadows-shadow-l);
    }
  }
}

.top-list-item {
  display: flex;
  flex-direction: column;
  max-width: 250px;
}

.top-list-item-skeletons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  max-width: 250px;
}

.top-list-title {
  font: var(--desktop-text-lg-semibold);
}

.top-list-subtitle {
  overflow: hidden;

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-list-icon {
  position: relative;
  line-height: 0;
}

.home-navigation-footer {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;
  margin-top: var(--spacing-050);
  padding: 0 var(--spacing-200);

  background: var(--background-primary);
  border-radius: var(--border-radius-150);
}

.logo {
  flex-shrink: 1;
  min-width: 0;
}
</style>
